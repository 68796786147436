<template>
  <UFormGroup
    class="text-sm"
    :label="label"
    :name="id"
  >
    <PrimeCalendar
      class="relative mt-1"
      :input-id="id"
      :manual-input="false"
      :pt="calendarStyle"
      unstyled
      show-icon
      show-button-bar
      icon-display="input"
      date-format="dd/mm/yy"
      v-bind="$attrs"
    >
      <template #previousicon>
        <UIcon
          name="i-mdi-chevron-double-left"
          class="!size-6 text-blue-default"
        />
      </template>
      <template #nexticon>
        <UIcon
          name="i-mdi-chevron-double-right"
          class="!size-6 text-blue-default"
        />
      </template>
      <template #inputicon="{ clickCallback }">
        <UIcon
          name="i-mdi-calendar-month-outline"
          class="absolute right-[.75rem] top-1/2 -mt-3 !size-6 text-grey-blue"
          @click="clickCallback"
        />
      </template>
    </PrimeCalendar>
  </UFormGroup>
</template>

<script setup lang="ts">
import type { CalendarPassThroughOptions } from 'primevue/calendar'
import type { PassThrough } from 'primevue/ts-helpers'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

withDefaults(
  defineProps<{
    id: string
    label: string
    required?: boolean
  }>(),
  {
    required: false
  }
)

const calendarStyle = computed<PassThrough<CalendarPassThroughOptions>>(() => {
  return {
    root: ({ props }) => ({
      class: [
        // display and position
        'relative',
        'flex',
        'w-full',

        // Misc
        { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled }
      ]
    }),
    group: {
      class: ['text-xs', 'font-semibold', 'flex-1']
    },
    input: () => ({
      class: ['rounded-lg py-3 px-4 w-full text-grey-blue border-dark-grey text-xs']
    }),
    inputicon: {
      class: ['absolute', 'top-[50%]', '-mt-2', 'text-grey-blue', 'right-[.75rem]']
    },
    panel: {
      class: [
        // Size
        'w-auto p-4',

        // Shape
        'border rounded-lg',
        'shadow-md',

        // Colors
        'bg-white',
        'border-dark-grey',

        // misc
        'overflow-x-auto'
      ]
    },
    header: {
      class: 'flex justify-between py-1.5'
    },
    title: {
      class: 'text-dark-purple font-bold flex space-x-1 justify-center'
    },
    table: {
      class: 'w-full'
    },
    weekDay: {
      class: 'w-7 h-7 flex items-center justify-center mx-2.5 my-1 text-grey-blue font-semibold'
    },
    daylabel: ({ props, context }) => {
      // get first date selected in the calendar and the last date selected in the calendar then shade the dates in between
      let selectedDates: Date[] = []
      let isDateBetweenStartAndEndDate = false
      if (props.modelValue) {
        selectedDates = props.modelValue
        isDateBetweenStartAndEndDate = dayjs(
          new Date(context.date.year, context.date.month, context.date.day)
        ).isBetween(selectedDates[0], selectedDates[selectedDates.length - 1])
      }

      return {
        class: [
          'font-bold',
          'w-7 h-7',
          'flex items-center justify-center',
          'mx-2.5 my-1',
          'rounded-full',

          // colors
          {
            'bg-dark-grey text-white': context.date.today,
            'bg-blue-7 text-blue-default': isDateBetweenStartAndEndDate,
            'text-grey-blue hover:bg-blue-7 hover:text-blue-default':
              !isDateBetweenStartAndEndDate && !context.date.today && context.date.selectable,
            'bg-blue-default text-white': context.selected && !isDateBetweenStartAndEndDate,
            'text-dark-grey pointer-events-none': !context.otherMonth && (!context.date.selectable || context.disabled),
            'hidden pointer-events-none': context.otherMonth && !context.selected
          },

          // states
          'hover:cursor-pointer'
        ]
      }
    },
    buttonbar: {
      class: 'flex justify-between mt-4'
    },
    todayButton: {
      root: {
        class:
          'bg-white text-xs border-2 border-blue-default text-blue-default font-semibold px-4 py-1.5 rounded-lg hover:bg-blue-3 hover:text-white hover:border-blue-3'
      }
    },
    clearButton: {
      root: {
        class:
          'bg-white text-xs border-2 border-blue-default text-blue-default font-semibold px-4 py-1.5 rounded-lg hover:bg-blue-3 hover:text-white hover:border-blue-3'
      }
    },
    monthPicker: {
      class: 'grid grid-cols-3 gap-3'
    },
    month: {
      class: 'text-center hover:cursor-pointer hover:bg-blue-7 py-1 rounded-md'
    },
    yearPicker: {
      class: 'grid grid-cols-2 gap-3'
    },
    year: {
      class: 'hover:cursor-pointer hover:bg-blue-7 py-1 text-center rounded-md'
    }
  }
})
</script>
